import { CALL_API } from 'middleware/api'
import { API_ROOT } from 'config/api'
import {
  FETCH_REFERRALS,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
} from './types'

export const getReferrals = (page, token) => ({
  [CALL_API]: {
    types: [FETCH_REFERRALS, FETCH_REFERRALS_SUCCESS, FETCH_REFERRALS_FAILURE],
    endpoint: `${API_ROOT}/referrals?page=${page}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
})
