import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import MediaQuery from 'react-responsive'
import { HeaderDesktop, HeaderMobile } from 'yapay'
import { getUser } from 'actions'

const Header = ({ getUser, name, email, match }) => {
  useEffect(() => {
    getUser(match.params.token)
  }, [getUser, match.params.token])

  return (
    <React.Fragment>
      <MediaQuery query="(max-width: 1199px)">
        <HeaderMobile title="Portal de Parceiros" />
      </MediaQuery>
      <MediaQuery query="(min-width: 1200px)">
        <HeaderDesktop user={{ name, email }} className="mb-8 relative z-20" />
      </MediaQuery>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  name: state.user.data.name,
  email: state.user.data.email,
})

export default withRouter(
  connect(
    mapStateToProps,
    { getUser },
  )(Header),
)
