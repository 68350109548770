import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  Currency,
  ShadowyBox,
  Paginate,
  PaginateArrows,
  Tabs,
  DataTable,
  Loading,
} from 'yapay'
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip as RTooltip,
  ResponsiveContainer,
} from 'recharts'
import MediaQuery from 'react-responsive'
import 'yapay/dist/assets/css/utils.css'

const cols = [
  {
    id: 'month',
    title: 'Mês',
  },
  {
    id: 'email',
    title: 'E-mail',
  },
  {
    id: 'inOut',
    title: 'Origem',
  },
  {
    id: 'revenues',
    title: 'Faturamento',
  },
  {
    id: 'commission',
    title: 'Comissão',
  },
  {
    id: 'paymentMethod',
    title: 'Pagamento',
  },
]

const Home = ({
  referralsList,
  loadingList,
  pagination,
  setCurrentPage,
  balance,
  totalReferrals,
  amountWithSale,
  amountReferralsLastDays,
  sellersReferrals,
  annualAverage,
}) => {
  return (
    <React.Fragment>
      <MediaQuery query="(max-width: 1199px)">
        <Tabs className="flex-1">
          {mobileTabStart(
            annualAverage,
            balance,
            totalReferrals,
            amountWithSale,
            amountReferralsLastDays,
            sellersReferrals,
          )}
          {mobileTabReferrals(referralsList, setCurrentPage, pagination)}
        </Tabs>
      </MediaQuery>
      <MediaQuery query="(min-width: 1200px)">
        <div
          style={{ width: '1200px' }}
          className="mx-auto mb-12 relative z-10"
        >
          {statsBoxes(
            balance,
            totalReferrals,
            amountWithSale,
            amountReferralsLastDays,
            sellersReferrals,
          )}
          <AreaChart
            width={1200}
            height={200}
            data={annualAverage}
            style={{ fontFamily: 'Roboto, sans-serif' }}
          >
            <XAxis dataKey="name" padding={{ left: 35, right: 35 }} />
            <RTooltip />
            <Area
              type="monotone"
              dataKey="valor"
              unit=" Reais"
              stroke="#0ab155"
              strokeWidth={3}
              fill="#0ab155"
              dot={{ stroke: '#0ab155', strokeWidth: 3 }}
            />
          </AreaChart>
          <ShadowyBox className="mt-8">
            <Text h3 className="mt-0 mb-4">
              Indicações
            </Text>
            <div className="relative">
              <DataTable rows={referralsList} cols={cols} className="mb-4" />
              {loadingList && <Loading fullPage>Carregando indicações</Loading>}
            </div>
            <Paginate
              count={pagination.total}
              perPage={10}
              currentPage={pagination.current}
              onClick={action => setCurrentPage(action)}
              noBorder
            />
          </ShadowyBox>
        </div>
      </MediaQuery>
    </React.Fragment>
  )
}

const statsBoxes = (
  balance,
  totalReferrals,
  amountWithSale,
  amountReferralsLastDays,
  sellersReferrals,
) => {
  return (
    <div className="xl:flex justify-between xl:mb-8">
      <ShadowyBox className="flex items-center mb-4 flex-1">
        <div className="flex items-center">
          <span className="text-5xl text-green mr-4">{totalReferrals}</span>
          <Text className="my-0">
            Total de
            <br />
            indicações
          </Text>
        </div>
      </ShadowyBox>
      <ShadowyBox className="flex items-center flex-1 mb-4 xl:ml-8">
        <div className="flex items-center">
          <span className="text-5xl text-green mr-4">{amountWithSale}</span>
          <Text className="my-0">
            Indicações com
            <br />
            vendas
          </Text>
        </div>
      </ShadowyBox>
      <ShadowyBox className="flex items-center flex-1 mb-4 xl:ml-8">
        <div className="flex items-center">
          <span className="text-5xl text-green mr-4">
            {amountReferralsLastDays}
          </span>
          <Text className="my-0">
            Indicações no
            <br />
            último mês
          </Text>
        </div>
      </ShadowyBox>
      <ShadowyBox className="flex items-center flex-1 mb-4 xl:ml-8">
        <div>
          <Text className="mt-0 mb-2">Saldo disponível</Text>
          <span className="text-3xl text-green mr-4">
            <Currency value={balance} />
          </span>
        </div>
      </ShadowyBox>
    </div>
  )
}

const mobileTabStart = (
  annualAverage,
  balance,
  totalReferrals,
  amountWithSale,
  amountReferralsLastDays,
  sellersReferrals,
) => {
  return (
    <div label="Início">
      <div className="p-4">
        {statsBoxes(
          balance,
          totalReferrals,
          amountWithSale,
          amountReferralsLastDays,
          sellersReferrals,
        )}
        <ShadowyBox className="p-0 mb-4">
          <ResponsiveContainer height={200}>
            <AreaChart data={annualAverage}>
              <RTooltip />
              <Area
                type="monotone"
                dataKey="valor"
                stackId="1"
                stroke="#6CB52D"
                fill="#6CB52D"
              />
            </AreaChart>
          </ResponsiveContainer>
        </ShadowyBox>
      </div>
    </div>
  )
}

const mobileTabReferrals = (referralsList, setCurrentPage, pagination) => {
  return (
    <div label="Indicações">
      <div className="p-4">
        {referralsList.length > 0 ? (
          <div>
            <PaginateArrows
              currentPage={pagination.current}
              totalItems={pagination.total}
              perPage={10}
              onClick={current => setCurrentPage(current)}
              className="mb-4"
              fullWidth
            />
            {referralsList.map((referral, index) => (
              <React.Fragment key={index}>
                <ShadowyBox color="success" className="mb-4">
                  <div className="mb-4">{referral.email}</div>
                  <div className="flex justify-between">
                    <div>
                      <small className="block mb-2">Comissão</small>
                      <strong className="text-green">
                        <Currency value={referral.commission.value} kformat />
                      </strong>
                    </div>
                    <div>
                      <small className="block mb-2">Faturamento</small>
                      <Currency value={referral.revenues.value} kformat />
                    </div>
                    <div>
                      <small className="block mb-2">Origem</small>
                      <span>{referral.inOut}</span>
                    </div>
                  </div>
                </ShadowyBox>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div>
            <Text className="text-xl text-center">
              Você ainda não possui nenhuma indicação :(
            </Text>
            <Link
              className="button button--success button--small w-full"
              to="/new"
            >
              Faça uma indicação
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
