import {
  FETCH_REFERRALS,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
} from 'actions/types'

const INITIAL_STATE = {
  data: null,
  error: null,
  loading: false,
}

export default function(state = INITIAL_STATE, action) {
  let error
  switch (action.type) {
    case FETCH_REFERRALS:
      return {
        ...state,
        error: null,
        loading: true,
      }

    case FETCH_REFERRALS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
      }

    case FETCH_REFERRALS_FAILURE:
      error = action.payload || { message: action.payload }
      return {
        ...state,
        error,
        loading: false,
      }

    default:
      return state
  }
}
