import { combineReducers } from 'redux'
import referrals from 'reducers/ReferralsReducer'
import stats from 'reducers/StatsReducer'
import user from 'reducers/UserReducer'

export default combineReducers({
  user,
  stats,
  referrals,
})
