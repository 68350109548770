import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers'
import api from 'middleware/api'

const configureStore = () => {
  let store = createStore(rootReducer, applyMiddleware(api))
  return store
}

export default configureStore
