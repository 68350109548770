import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Currency } from 'yapay'
import { getReferrals, getStats, getUser } from 'actions'
import Home from 'components/Home'
import Loader from 'components/Loader'

const HomeContainer = ({
  getReferrals,
  getStats,
  referrals,
  balance,
  totalReferrals,
  amountWithSale,
  amountReferralsLastDays,
  sellersReferrals,
  annualAverage,
  month,
  match,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getStats(match.params.token)
  }, [getStats, match.params.token])

  useEffect(() => {
    getReferrals(currentPage, match.params.token)
  }, [currentPage, getReferrals, match.params.token])

  const { loading, data } = referrals
  if (!data) {
    return (
      <div style={{ width: '1200px' }} className="mx-auto mb-12 relative z-10">
        <Loader />
      </div>
    )
  }

  const referralsList = data.data.map(referral => {
    let {
      id,
      month,
      email,
      inOut,
      commission,
      revenues,
      paymentMethod,
    } = referral

    let date_month = new Date(month)
    month = date_month.getMonth() + 1 + '/' + date_month.getFullYear()

    revenues = Number(revenues)
    commission = Number(commission)
    return {
      id,
      month,
      email,
      inOut,
      revenues: {
        value: revenues,
        component: <Currency value={revenues} />,
      },
      commission: {
        value: commission,
        component: <Currency value={commission} />,
      },
      paymentMethod,
    }
  })

  const { current, total } = data

  return (
    <Home
      loadingList={loading}
      referralsList={referralsList}
      pagination={{ current, total }}
      setCurrentPage={setCurrentPage}
      balance={balance}
      month={month}
      totalReferrals={totalReferrals}
      amountWithSale={amountWithSale}
      amountReferralsLastDays={amountReferralsLastDays}
      sellersReferrals={sellersReferrals}
      annualAverage={annualAverage}
    />
  )
}

HomeContainer.propTypes = {
  props: PropTypes.object,
}

const mapStateToProps = state => ({
  referrals: state.referrals,
  balance: state.stats.data.balance,
  totalReferrals: state.stats.data.totalReferrals,
  amountWithSale: state.stats.data.amountWithSale,
  amountReferralsLastDays: state.stats.data.amountReferralsLastDays,
  sellersReferrals: state.stats.data.sellersReferrals,
  annualAverage: state.stats.data.annualAverage,
})

export default withRouter(
  connect(
    mapStateToProps,
    { getReferrals, getStats, getUser },
  )(HomeContainer),
)
