import { CALL_API } from 'middleware/api'
import { API_ROOT } from 'config/api'
import { FETCH_STATS, FETCH_STATS_SUCCESS, FETCH_STATS_FAILURE } from './types'

export const getStats = token => ({
  [CALL_API]: {
    types: [FETCH_STATS, FETCH_STATS_SUCCESS, FETCH_STATS_FAILURE],
    endpoint: `${API_ROOT}/stats`,
    options: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
})
