import React from 'react'
import { Route } from 'react-router-dom'
import ErrorBoundary from '../containers/ErrorBoundary'
import HomeContainer from 'containers/HomeContainer'
import HeaderContainer from 'containers/HeaderContainer'
import NewReferralContainer from 'containers/NewReferralContainer'

const Routes = () => (
  <ErrorBoundary>
    <Route path="/:token" exact component={HeaderContainer} />
    <Route path="/:token" exact component={HomeContainer} />
    <Route path="/new" exact component={NewReferralContainer} />
  </ErrorBoundary>
)

export default Routes
