import {
  FETCH_STATS,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE,
} from 'actions/types'

const INITIAL_STATE = {
  data: {
    balance: 0,
    totalReferrals: 0,
    amountWithSale: 0,
    amountReferralsLastDays: 0,
    sellersReferrals: 0,
    annualAverage: [],
  },
  error: null,
  loading: false,
}

export default function(state = INITIAL_STATE, action) {
  let error
  switch (action.type) {
    case FETCH_STATS:
      return {
        ...state,
        error: null,
        loading: true,
      }

    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
      }

    case FETCH_STATS_FAILURE:
      error = action.payload || { message: action.payload }
      return {
        ...state,
        error,
        loading: false,
      }

    default:
      return state
  }
}
