// Referrals
export const FETCH_REFERRALS = 'FETCH_REFERRALS'
export const FETCH_REFERRALS_SUCCESS = 'FETCH_REFERRALS_SUCCESS'
export const FETCH_REFERRALS_FAILURE = 'FETCH_REFERRALS_FAILURE'

// Stats
export const FETCH_STATS = 'FETCH_STATS'
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS'
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE'

// User
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
